import React from 'react'

import { CardGroup, Card, CardContent, CardActions } from '../../styles/card'
import { CertIcon } from './Icons'
import content from './content'

import Clickable from '../Clickable'

export default function Services() {
  return (
    <CardGroup>
      <Card>
        <CardContent>
          <CertIcon />
          <h2>{content.certificate.title}</h2>
          <CardActions>
            <Clickable
              as="link"
              primary
              to={content.certificate.register.url}
              content={content.certificate.register.description}
            />
          </CardActions>
        </CardContent>
      </Card>
    </CardGroup>
  )
}
