const content = {
  erp: {
    title: 'SCONTTI ERP',
    icon: 'cert',
    register: {
      url: 'https://register.scontti.com',
      description: 'Registrar'
    },
    login: {
      url: 'https://app.scontti.com',
      description: 'Acessar'
    }
  },
  certificate: {
    title: 'Certificado digital',
    icon: 'cert',
    register: {
      url: '/certificado-digital',
      description: 'Acessar'
    }
  }
}

export default content
