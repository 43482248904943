import React from 'react'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Services from '../components/Services'
import { Wrapper } from '../styles/layout'
import Header from '../styles/Header'

export default function Servicos() {
  return (
    <Layout>
      <Seo title="Serviços" />
      <Wrapper>
        <Header as="h1" content="Serviços" />
        <Services />
      </Wrapper>
    </Layout>
  )
}
